<template>
    <div style="overflow: hidden;padding:15px;" :style="`background:${event_settings.background};`">
        <div v-if="event_settings.title[landing.current_lang]" class="editor_preview" style="border-radius:0;margin:0 5px;padding:0;min-height: auto;height:auto;" v-html="event_settings.title[landing.current_lang]"></div>
        <div style="padding:0 5px;margin-bottom:15px;display: flex;justify-content: flex-end;align-items: center;">
            <a  :style="`color:${event_settings.all_events.styles.color};font-weight:${event_settings.all_events.styles.fontWeight};font-style:${event_settings.all_events.styles.fontStyle};font-family:${event_settings.all_events.styles.fontFamily};font-size:${event_settings.all_events.styles.fontSize}px;`" :href="event_settings.all_events.link[landing.current_lang]" >
                {{event_settings.all_events.text[landing.current_lang]}}
            </a>
        </div>
        <div style="padding:0 30px;">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide :class="slide.id" v-for="slide in events" :key="slide.id"
                              style="display:flex;background:#fff;flex-direction:column;" :style="`border-radius:${event_settings.radius}px;cursor:pointer;width:${event_settings.vertical.width}px;border:1px solid ${event_settings.border_color};height:${event_settings.vertical.height}px;`">
                    <div  style="width:100%;" :style="`border-radius:${event_settings.radius}px;`">
                        <img :src="slide.main_image" :alt="slide.main_image" style="width:100%;object-fit: cover;" :style="`height:${event_settings.vertical.img_height}px;border-top-left-radius:${event_settings.radius}px;border-top-right-radius:${event_settings.radius}px;`">
                    </div>
                    <div style="border-radius:0;display: flex;padding:0 10px;flex-direction: column;font-size:14px;justify-content: space-evenly;">
                        <p style="border-radius:0;min-height:auto;height:auto;margin:0;overflow-wrap: break-word;" v-html="slide.name[landing.current_lang]"></p>
                        <p style="border-radius:0;min-height:auto;height:auto;margin:0;overflow-wrap: break-word;" v-html="slide.description[landing.current_lang]"></p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import EventService from "../../../../services/event.service";
    import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
    import SwiperCore, { Autoplay} from 'swiper';
    SwiperCore.use([Autoplay]);
  export default {
    name: "EventsPreview",
    components:{
      Swiper,
      SwiperSlide
    },
    props:['event_settings'],
    data(){
      return {
        swiperOptions:{
          slidesPerView: 1,
          spaceBetween: 15,
          centeredSlides:true,
          initialSlide:1,
          direction:"horizontal",
          loop: true,
          centerInsufficientSlides:true,
          autoplay:{
            delay:+this.event_settings.autoplay*1000
          },
          speed: 700,
        },
        events:[]
      }
    },
    watch:{
      "event_settings.autoplay":function (val) {
        this.$refs.mySwiper.swiperInstance.params.autoplay.delay = +val*1000;
      }
    },
    computed:{
      ...mapState(['landing']),
    },
    async mounted() {

      const payload = {
        hotel_id:this.$route.params.id,
        from:this.$moment().format('YYYY-MM-DD'),
        to:this.$moment().add('10','day').format('YYYY-MM-DD'),
      };
      this.events = await EventService.getEventsForMainPage(payload);
      this.$nextTick(() => {
        console.log(this.$refs.mySwiper);
      })
    }
  }
</script>

<style lang="scss">
.editor_preview p {
    margin:0;
}
</style>
